import { Box, Container, Heading } from "@chakra-ui/react"
import React from "react"

import FoldUpAnimation from "../../DefinitionComponents/Animation-Components/FoldUpAnimation"
import InViewTrigger from "../../DefinitionComponents/Utility/UX/InViewTrigger"
import GImage from "../GImage"
import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
function LogoHeadlineTeaserVeo({ headline, subheadline, ...restProps }) {
  return (
    <Container variant="layoutContainer" minH="30vh" my={24}>
      <InViewTrigger threshold={0.4}>
        {inView => (
          <FadeInAnimation maxW={"7xl"} inView={inView}>
            <Box>
              <GImage
                mx="auto"
                maxW={{ base: "64px", lg: "180px" }}
                src="logoveomed.svg"
              />
              <Heading
                mt={12}
                fontSize={{ base: "3xl", md: "5xl", lg: "5xl" }}
                fontWeight="normal"
                color="brand.gray.vlight"
                textAlign={"center"}
              >
                {headline}
              </Heading>
              <Heading
                mx={"auto"}
                mt={4}
                maxW={{ base: "100%", lg: "680px" }}
                fontSize={{ base: "md", md: "lg", lg: "lg" }}
                color="brand.gray.light"
                textAlign={"center"}
                fontWeight="normal"
              >
                {subheadline}
              </Heading>
            </Box>
          </FadeInAnimation>
        )}
      </InViewTrigger>
    </Container>
  )
}

export default LogoHeadlineTeaserVeo
